.hover-container {
    position: relative;
    cursor: pointer;
} 
.hidden-text {
    position: fixed;
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}