#map {
  float: left;
  clear: left;
  height: 98vh;
  width: 38vw;
  padding: 1vh 1vw 1vh 1vw;
}
.leaflet-container{
  border: 2px solid #1A1A1A;
  border-radius: 15px;
}
#data {
  float: left;
  clear: right;
  height: 98vh;
  width: 58vw;
  padding: 1vh 1vw 1vh 1vw;
}

#hrChart {
  width: calc(70%);
  height: calc(60%);
}

.summary {
  table-layout: fixed;
}

.summary td {
  width: 5em;
  height: 5em;
  text-align: center;
  border: 2px solid black;
  border-radius: 50%;
}

/* Mobiililaitteille */
@media only screen and (max-width: 900px) {
  #map {
    width: 98vw;
    height: 98vw;
    margin: 1vw;
    padding: 0;
  }
  #data {
    width: 98vw;
    height: fit-content;
    margin: 1vw;
    padding: 0;
  }
  .summary td {
    width: calc(98vw / 6);
    height: calc(98vw / 6);
  }
}