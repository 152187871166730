#kirjaudu{
    width: fit-content;
    text-align: center;
    margin: 5em auto;
    background-image: url('./background.svg');
    background-color: #dfdbe5;
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    padding: 3em;

    
}

#kirjaudu h2 {
    margin: 0 0 1em 0;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 2em;
    font-weight: 600;
}
#kirjaudu div {
    margin-bottom: 2em;
}

#kirjaudu button {
    margin: 0;
    appearance: none;
    background-color: white;
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    box-sizing: border-box;
    color: #3B3B3B;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    min-height: 60px;
    min-width: 0;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    will-change: transform;
}

#kirjaudu button:disabled {
    pointer-events: none;
}
#kirjaudu button:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

#kirjaudu button:active {
    box-shadow: none;
    transform: translateY(0);
}
